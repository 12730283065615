import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, InputField } from "..";
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon, VimeoIcon, YoutubeIcon } from "../../assets";
import { SHEET_DB_URL } from "../../config";
import { Toast } from "../toast";

import styles from "./styles.module.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [displayToast, setDisplayToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });

  const subscribeToMailList = async (e) => {
    e.preventDefault();
    setLoad(true);
    fetch(SHEET_DB_URL, {
      method: "POST",
      body: JSON.stringify({ json: { email }, sheet: "Mailing List" }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response?.status >= 400) {
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        } else {
          setEmail("");
          setToastDetails({ toastState: "success", message: "You have joined our mailing list!" });
          setDisplayToast(true);
        }
      })
      .catch(() => {
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      })
      .finally(() => setLoad(false));
  };

  return (
    <div className={styles.container}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}
      <footer>
        <section className="container">
          <div>
            <h2>USEFUL LINKS</h2>
            <ul>
              <li>
                <a href="https://medium.com/genesys-tech-hub">OUR BLOG</a>
              </li>
              <li>
                <Link to="https://learnable.genesystechhub.com">LEARNING</Link>
              </li>
              <li>
                <Link to="/startzone">STARTZONE</Link>
              </li>
              <li>
                <a href="http://ignite.genesystechhub.com/">IGNITE</a>
              </li>
            </ul>
          </div>
          <div>
            <h2>LEARNING</h2>
            <ul>
              <li>
                <Link to="https://learnable.genesystechhub.com">LEARNABLE</Link>
              </li>
              <li>
                <Link to="/codeville">CODEVILLE</Link>
              </li>
              <li>
                <Link to="/sparks">SPARKS</Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>LOCATION</h2>
            <p>
              KILOMETER 7, ENUGU/
              <br />
              PORT HARCOURT,
              <br />
              EXPRESSWAY,
              <br />
              CENTENARY CITY, ENUGU,
              <br />
              NIGERIA.
            </p>
          </div>
          <div>
            <h1>Stay Connected</h1>
            <p>
              Join our mailing list to receive information about our <br /> work and new learning opportunities.
            </p>
            <div className={styles["form"]}>
              <form onSubmit={subscribeToMailList}>
                <InputField
                  placeholder="Enter Your Email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  required
                />
                <Button filledType={true} disabled={load}>
                  {load ? "Loading..." : "SUBSCRIBE"}
                </Button>
              </form>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className={styles.copyright}>
              <span>&copy; GENESYS 2022</span>
              <span>POWERED BY HUBLY</span>
            </div>
            <div className={styles.links}>
              <span>
                <a target="_blank" rel="noReferrer" href="https://twitter.com/genesystechhub">
                  <TwitterIcon />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noReferrer" href="https://web.facebook.com/genesyshub/?_rdc=1&amp;_rdr">
                  <FacebookIcon />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noReferrer" href="https://www.linkedin.com/company/genesystechhub">
                  <LinkedinIcon />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noReferrer" href="https://www.instagram.com/genesystechhub/">
                  <InstagramIcon />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noReferrer" href="https://www.youtube.com/channel/UCjiSVpNnioaySY7I9-4Ia7w">
                  <YoutubeIcon />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noReferrer" href="https://www.tiktok.com/@genesystechhub">
                  <VimeoIcon />
                </a>
              </span>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export { Footer };
