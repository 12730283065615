import React, { useRef } from "react";
import { RightArrowWhite, rocket } from "../../assets";
import { CodeVilleButton, CodeVilleFooter, CodeVilleNavbar, Modal } from "../../components";
import { CODEVILLE_APP_LINKS } from "../../config";
import styles from "./styles.module.css";

const CodeVilleLayout = ({ children }) => {
  const modalRef = useRef(null);

  return (
    <div className={styles.codeVilleMainContainer}>
      <Modal ref={modalRef} initialLoadState={false}>
        <div className={styles.codeVilleVirtualModal}>
          <img src={rocket} alt="rocket-img" />
          <h2>Are you interested in Robotics and Programming?</h2>
          <h2>Are you between the ages of 8 and 12?</h2>
          <p> Apply to Codeville Kids Code Camp!</p>
          <CodeVilleButton
            label={`Apply`}
            buttonType="primary"
            icon={<RightArrowWhite />}
            onClick={() => window.open(CODEVILLE_APP_LINKS.juniorAcademy)}
          />
        </div>
      </Modal>

      <CodeVilleNavbar />
      <section className={styles.codeVillePageWrapper}>{children}</section>
      <CodeVilleFooter />
    </div>
  );
};

export { CodeVilleLayout };
